<template> 
  <div v-if="Object.keys(store).length">
    <store-settings-header
      :titleBarActions="titleBarActions"
    />
    <div class="row no-gutters">
      <key-val-line prop="Online">{{ store.storeOnline ? 'Yes' : 'No' }}</key-val-line>

      <key-val-line prop="Hours">
        <key-val-list
          :keys="hours"
          :values="hours"
          :keyGet="el => el.fullDisplay"
          :valueGet="el => `${el.start} - ${el.end}`"
        />
      </key-val-line>

      <key-val-line prop="Prep Time">{{ store.storePrepTime }}</key-val-line>

      <key-val-line prop="Phone Number">{{ store.storePhoneNumber }}</key-val-line>

      <key-val-line prop="Email">{{ store.storeEmail }}</key-val-line>

      <key-val-line prop="Size Names">{{ spreadItems(store.storeSizes.map(size => size.sizeName)) }}</key-val-line>

      <key-val-line prop="Employee Discount" v-if="superuser">
        <key-val-list
          :keys="[{label: store.employeeDiscountOwnerDescription}]"
          :values="[{amount: store.employeeDiscountValue}]"
          :keyGet="el => el.label"
          :valueGet="el => (el.amount * 100) + '%'"
        />
      </key-val-line>

      <key-val-line prop="Operator">{{ store.storeOperatorName }}</key-val-line>

      <key-val-line prop="Operator Identifier">{{ store.storeOperatorIdent }}</key-val-line>

      <key-val-line
        prop="Employee Discount Type"
        v-if="superuser"
      >{{ store.employeeDiscountTypeDescription }}</key-val-line>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { spreadItems, extractWeekHours } from "helpers";
import keyValLine from "../components/key_val_line.vue";
import keyValList from "../components/key_val_list.vue";
import imageBlock from "../components/image_block.vue";
import storeSettingsHeader from "components/store_settings_header.vue";
import environment_selector from "mixins/environment_selector";

export default {
    name: "StoreSettings",
    data() {
        return {
            titleBarActions: [
                {
                    type: "edit",
                    display: "Edit",
                    run: () => {
                        this.$router.push({ name: `store-settings-edit`, params: {
                            env: this.environment
                        }});
                    }
                }
            ]
        };
    },
    mixins: [environment_selector],
    computed: {
        hours() {
            return extractWeekHours(this.store.localStoreTimeWeekly);
        },
        ...mapState({
            imageServer: state => state.imageServer
        }),
        ...mapGetters(["store", "pos", "user", "superuser"])
    },
    methods: {
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Settings`;
            }
        },
        spreadItems,
        extractWeekHours,
        ...mapActions(["syncStore"])
    },
    components: {
        keyValLine,
        keyValList,
        imageBlock,
        storeSettingsHeader
    },
    watch: {
        store() {
            this.resolvePageTitle();
        }
    },
    created() {
        this.resolvePageTitle();
    }
};
</script>
