var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-md-10 col-lg-8 col-xl-6" }, [
      _c("div", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          { staticClass: "col-auto" },
          _vm._l(_vm.keys, function (key) {
            return _c(
              "div",
              [
                _vm.keyGet
                  ? [_vm._v(" " + _vm._s(_vm.keyGet(key)) + " ")]
                  : [_vm._v(" " + _vm._s(key) + " ")],
              ],
              2
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "col-auto offset-1" },
          _vm._l(_vm.values, function (value) {
            return _c(
              "div",
              [
                _vm.valueGet
                  ? [_vm._v(" " + _vm._s(_vm.valueGet(value)) + " ")]
                  : [_vm._v(" " + _vm._s(value) + " ")],
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }