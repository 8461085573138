<template>
    <div class="row">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6">
            <div class="row no-gutters">
                <div class="col-auto">
                    <div v-for="key in keys">
                        <template v-if="keyGet">
                            {{ keyGet(key) }}
                        </template>

                        <template v-else>
                            {{ key }}
                        </template>
                    </div>
                </div>

                <div class="col-auto offset-1">
                    <div v-for="value in values">
                        <template v-if="valueGet">
                            {{ valueGet(value) }}
                        </template>

                        <template v-else>
                            {{ value }}
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KeyValList',
    props: {
        keys: {
            type: Array,
            required: true,
        },
        values: {
            type: Array,
            required: true,
        },
        keyGet: Function,
        valueGet: Function,
    },
}
</script>

<style lang="scss">
</style>
