var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.store).length
    ? _c(
        "div",
        [
          _c("store-settings-header", {
            attrs: { titleBarActions: _vm.titleBarActions },
          }),
          _c(
            "div",
            { staticClass: "row no-gutters" },
            [
              _c("key-val-line", { attrs: { prop: "Online" } }, [
                _vm._v(_vm._s(_vm.store.storeOnline ? "Yes" : "No")),
              ]),
              _c(
                "key-val-line",
                { attrs: { prop: "Hours" } },
                [
                  _c("key-val-list", {
                    attrs: {
                      keys: _vm.hours,
                      values: _vm.hours,
                      keyGet: (el) => el.fullDisplay,
                      valueGet: (el) => `${el.start} - ${el.end}`,
                    },
                  }),
                ],
                1
              ),
              _c("key-val-line", { attrs: { prop: "Prep Time" } }, [
                _vm._v(_vm._s(_vm.store.storePrepTime)),
              ]),
              _c("key-val-line", { attrs: { prop: "Phone Number" } }, [
                _vm._v(_vm._s(_vm.store.storePhoneNumber)),
              ]),
              _c("key-val-line", { attrs: { prop: "Email" } }, [
                _vm._v(_vm._s(_vm.store.storeEmail)),
              ]),
              _c("key-val-line", { attrs: { prop: "Size Names" } }, [
                _vm._v(
                  _vm._s(
                    _vm.spreadItems(
                      _vm.store.storeSizes.map((size) => size.sizeName)
                    )
                  )
                ),
              ]),
              _vm.superuser
                ? _c(
                    "key-val-line",
                    { attrs: { prop: "Employee Discount" } },
                    [
                      _c("key-val-list", {
                        attrs: {
                          keys: [
                            {
                              label: _vm.store.employeeDiscountOwnerDescription,
                            },
                          ],
                          values: [{ amount: _vm.store.employeeDiscountValue }],
                          keyGet: (el) => el.label,
                          valueGet: (el) => el.amount * 100 + "%",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("key-val-line", { attrs: { prop: "Operator" } }, [
                _vm._v(_vm._s(_vm.store.storeOperatorName)),
              ]),
              _c("key-val-line", { attrs: { prop: "Operator Identifier" } }, [
                _vm._v(_vm._s(_vm.store.storeOperatorIdent)),
              ]),
              _vm.superuser
                ? _c(
                    "key-val-line",
                    { attrs: { prop: "Employee Discount Type" } },
                    [_vm._v(_vm._s(_vm.store.employeeDiscountTypeDescription))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }